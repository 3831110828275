import ImageGallery from 'react-image-gallery';
import { useEffect, useState } from 'react';

const Gallery = () => {
    const [images, setImages] = useState([])

    useEffect(() => {
        if (images.length) {
            return
        }
        setImages(Array.from(Array(35)).map((_, i) => i + 1).map(i => ({
            original: `images/${i}.jpeg`,
            thumbnail: `images/${i}.jpeg`,
        })))
    }, [images])

    return <ImageGallery items={images} />;
}
export default Gallery;