import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Gallery from "./Gallery.js";

function App() {
  return (
    <Container maxWidth="lg">
      <Typography sx={{
        fontSize: {
          lg: 35,
          md: 33,
          sm: 33,
          xs: 28
        }
      }} my={3} align="center">
        Morros Zoe - 517A
        
      </Typography>
      <Gallery />
      <Box my={5}>
        <Card sx={{ minWidth: 275 }}>
           <CardContent>
            <Typography sx={{
              fontSize: {
                lg: 24,
                md: 24,
                sm: 16,
                xs: 16
              }
            }} color="000000" gutterBottom>
              Descripcion
            </Typography>
            <Typography variant="body2">
             Este acogedor apartamento con vista al Mar en las playas mas tranquilas de Cartagena exactamente en Manzanillo del mar es el lugar perfecto para disfrutar de unas vacaciones relajantes. Además, el edificio cuenta con zonas sociales impresionantes, incluyendo piscinas al aire libre, jacuzzi, baños Turco, gimnasio y un parque infantil los cuales podrás disfrutar, ademas cuenta con seguridad 24/7 y parqueadero.
             Desde el balcón del apartamento podrás apreciar una maravillosa vista al mar. El edificio cuenta con una salida directa a la playa para que goces de los hermosos paisajes costeros y podrás hacer uso de una de las carpas con 4 sillas ubicadas en la playa la que puedes reservar a través de la aplicación <a href="https://apps.apple.com/us/app/mi-comunidad-sdm/id1509062681">"Mi Comunidad SDM"</a> por un costo de $15,000 COP

            </Typography> <br/><Typography variant="body2">
             El apartamento con capacidad para 6 huespedes tiene 2 habitaciones.
             La habitación principal cuenta con cama olympic queen y baño privado, La segunda habitación tiene una cama sencilla y una cama tipo nido y dispone además de un sofa cama tamaño queen ubicado en la sala y un baño social. La cocina tiene todos los utensilios necesarios para poder preparar alimentos.

            </Typography> <br/><Typography variant="body2">
                    
             
      
            <Typography sx={{
              fontSize: {
                lg: 22,
                md: 22,
                sm: 14,
                xs: 14
              }
            }} color="000000" gutterBottom>
               Reglas
            </Typography>
             
            •	El check in comienza a las 15:00. El check out es a las 11:00. Early check in y late check out pueden tener un cargo adicional sujeto a disponibilidad. Consulte con su anfitrión.
            <br></br>
              
            •	Solo los huéspedes mencionados en la reserva están permitidos en el edificio.
            <br></br>
             
            •	Al momento de reservar se solicitarán los documentos de identidad de los huéspedes. Es importante enviar esto con tiempo ya que sin esta información NO se permitirá 
            <br></br>  
            el acceso al edificio o propiedad y demorará el check in.
            <br></br>
             
            •	Los huéspedes anunciados en la reserva seran verificados, cualquier persona extra deberá pagar el precio adicional por noche.
            <br></br> 
             
            •	Prohibido ingreso a menores sin compañía de familiares (se solicitará documento que valide su parentesco). 
            <br></br>
             
            •	Prohibidas las visitas, fiestas y reuniones en el apartamento.
            <br></br>
            
            •	Prohibido fumar dentro del apartamento. 
            <br></br>
             
            •	Prohibido el consumo de sustancias psicoactivas. 
            <br></br>

            •	Moderar el ruido, prohibida música en volumen alto.
            <br></br>
                                                                                       
            •	No se admiten Mascotas
            <br></br>
             
            •	Siempre apagar luces, estufa, entre otros al salir del apartamento o no hacer uso de estos.
            <br></br>
            
            •	Siempre cerrar puertas y ventanas cuando no estén en la casa, las fuertes brisas
             pueden dañar cosas, y ensuciarlo. 
            </Typography> <br/><Typography variant="body2">
                        
            El incumplimiento de cualquier regla llevará a la cancelación inmediata de la reserva, somos muy estrictos con las reglas.
            </Typography> <br/><Typography variant="body2">
            
            <Typography sx={{
              fontSize: {
                lg: 22,
                md: 22,
                sm: 14,
                xs: 14
              }
            }} color="000000" gutterBottom>
               Que Ofrece Este Lugar
            </Typography>
             
             Baños: Regadera Exterior, Agua Caliente, toallas, Gel de Baño, jabon y papel higenico
             <br></br>
             Lavandería: Lavadora y Secadora dentro del apartamento
             <br></br>
             Elementos básicos: aire acondicionado, ventilador de techo, detector de humo, toallas de playa, sábanas, Ganchos, Ropa de cama, Almohadas y mantas adicionales, cortinas blackout,
             Plancha, Caja fuerte y closet.
             <br></br>
             Entretenimiento: Smart TV y WiFi
             <br></br> 
             Area de Cocina: Refrigerador con hielera, congelador, estufa, horno, Microondas, tostadora, licuadora, bandeja para hornear, cafetera Francesa, cafetera de filtro, baterias de Ollas, juego vajilla para seis puestos, cubiertos, tazas, juego de vasos para seis, vaso plasticos, copas de vino, etc.
             </Typography> <br/><Typography variant="body2">
             Numero de Licencia 146810
            <br></br>
             Para informacion chat via 
            <a href="https://wa.me/17863552930">"WhatsApp"</a>
            </Typography> <br/><Typography variant="body2">
            
            <Typography sx={{
              fontSize: {
                lg: 24,
                md: 24,
                sm: 16,
                xs: 16
              }
            }} color="000000" gutterBottom>
               Description
            </Typography>
             
             This cozy 2 bedroom 2 bathroom apartment in Cartagena is the perfect place to enjoy a relaxing beach vacation.
             In addition, the building features impressive social areas, including an outdoor pool, jacuzzi, steam room, gym and a children's playground. The apartment also offers a direct exit to the beach, which means that guests can enjoy the beautiful coastal scenery every day and make the most of their time at the beach.
            </Typography> <br/><Typography variant="body2">
             
             The apartment with capacity for 6 guests has 2 bedrooms. The master bedroom has a olympic queen size bed and a private bathroom. The second bedroom has a twin size bed with trundle. In the living room there is a queen size sofa bed and a full bathroom. The kitchen has all the necessary utensils to cook.
            </Typography> <br/><Typography variant="body2">

             Sunbrella rental with 4 chairs on the beach has an additional cost of $15,000 COP, the rental is done through the <a href="https://apps.apple.com/us/app/mi-comunidad-sdm/id1509062681">"Mi Comunidad SDM"</a> app.
             
            </Typography> <br/><Typography variant="body2">
            <Typography sx={{
              fontSize: {
                lg: 22,
                md: 22,
                sm: 14,
                xs: 14
              }
            }} color="000000" gutterBottom>
               House Rules
            </Typography>

             • Check in begins at 3:00pm. Check out is at 11:00am. Early check in and late check out may have an additional charge subject to availability. Please check with your host.
            <br></br>
              
             • Only the guests mentioned in the reservation are allowed in the building.
            <br></br>
                 
             • At the time of booking, guests ID or passport will be requested. It is important to send this prior the check in, without this information access to the building or property will 
                <br></br>   
                NOT be allowed and will delay check in.
            <br></br>
            
             • All guests on reservation will be verified, any extra guests will have to pay the additional price per night.
            <br></br>
             
             • Visitors are not allowed.
            <br></br>

             • Unaccompanied minors are not allowed to enter or be left alone in the apartment (a document validating their relationship will be requested).
            <br></br>
              
             • Parties and meetings in the apartment are forbidden. 
            <br></br>
              
             • The consumption of psychoactive substances is prohibited. 
            <br></br>
              
             • Keep the music and noise to a moderate level, loud music is forbidden.
            <br></br>
                                       
             • No pets allowed.
            <br></br>
             • Always be sure to turn off lights, stove and other appliance when leaving the apartment. 
            <br></br>
            
             • Always close doors and windows when leaving the apartment as the strong breezes can damage items. 
            <br></br>
              
             • No smoking inside the apartment. 
            </Typography> <br/><Typography variant="body2">
              
             Failure to comply with any rule will lead to immediate cancellation of the reservation, we are very strict with the rules.
            </Typography> <br/><Typography variant="body2">
            <Typography sx={{
              fontSize: {
                lg: 22,
                md: 22,
                sm: 14,
                xs: 14
              }
            }} color="000000" gutterBottom>
               What This Place Offers
           </Typography>
             Bathroom: Hot water, Shower, hand soap, towels and toilet paper.
            <br></br>
            
             Laundry: Washer and Dryer in apartment.
            <br></br>
            
             Basic Essentials: Air conditioner, ceiling fans, smoke detector, beach towels, bed linens, clothes hangers, extra pillows, additional blankets, blackout curtains, Iron, safety deposit box and closet.
            <br></br>
            
             Entertainment: Smart TV and WiFi
            <br></br>
            
             Kitchen: Refrigerator with ice maker, stove, oven, Microwave, Toaster, Blender, Baking pans, french press, drip coffe maker, cookware set, dinnerware set for six, plastic tumblers, wine glass, etc.
            <br></br> 
            
             Free residential garage on premises -1 space
            <br></br>
            
             fitness center 
            <br></br>
             Smart lock
            </Typography> <br/><Typography variant="body2">
             License Number 146810
            <br></br>
             For information chat via 
            <a href="https://wa.me/17863552930">"WhatsApp"</a>
            </Typography> <br/><Typography variant="body2">
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box my={5}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.76716531846!2d-75.495531!3d10.5189962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef63b426770f8c1%3A0xa27717b17d8b441!2sMorros%20Zoe!5e0!3m2!1sen!2sus!4v1688418669494!5m2!1sen!2sus" title='Map' width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Box>
    </Container>
  );
}

export default App;
